import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Tabs from "../components/tabs"

export default ({data}) => {
  return (
    <Layout>
      <SEO title="Sections" />
            
      <div className="site-top-section">
        <div className="text">
          <h2>Artificial intelligence</h2>
          <p>AI is changing the world around us everyday. Follow, explore and understand it here.</p>
        </div>
      </div>

      <div className="sections-grid">
        {data.live.edges.map(({ node }) => (
          <Link className="section-card" to={`/${node.frontmatter.epic.toString().split(" ").join("-").toLowerCase()}/`}>
            <div className="section-text">
              <h3>
                {node.frontmatter.title}
              </h3>
              <h5 className="timestamp">
                Updated {node.frontmatter.date}
              </h5>
            </div>
          </Link>
        ))}
        {data.draft.edges.map(({ node }) => (
          <Link className="section-card draft" to={`/${node.frontmatter.epic.toString().split(" ").join("-").toLowerCase()}/`}>
            <div className="section-text">
              <h3>
                {node.frontmatter.title}
              </h3>
              <h5 className="timestamp">
                Coming {node.frontmatter.date}
              </h5>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    live: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "home" 
          }
          state: {
            eq: "live"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            type
            epic
            state
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
        }
      }
    }
    stories: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            ne: "home" 
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            epic
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
        }
      }
    }
    draft: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "home" 
          }
          state: {
            eq: "draft"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            type
            epic
            state
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`